export const GET_RELATED_ITEMS_DATA = 'GET_RELATED_ITEMS_DATA';
export const GET_RAW_CONTENT = 'GET_RAW_CONTENT';
export const GET_FOLDERISH_CONTENT = 'GET_FOLDERISH_CONTENT';
export const GET_PHASE_BANNER_CONTENT = 'GET_PHASE_BANNER_CONTENT';
export const GET_SITE_TITLE = 'GET_SITE_TITLE';
export const GET_GA_ID = 'GET_GA_ID';
export const GET_HOTJAR_ID = 'GET_HOTJAR_ID';
export const GET_CLIMATE_CHANGE_NOTIFICATION_STATE =
  'GET_CLIMATE_CHANGE_NOTIFICATION_STATE';
export const GET_NOTIFICATION_BANNER_STATE = 'GET_NOTIFICATION_BANNER_STATE';
