const ChartPlaceholderIcon = () => {
  return (
    <svg viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.8594 30.8594H4.26562V0.390625C4.26562 0.175781 4.08984 0 3.875 0H1.14062C0.925781 0 0.75 0.175781 0.75 0.390625V33.9844C0.75 34.1992 0.925781 34.375 1.14062 34.375H37.8594C38.0742 34.375 38.25 34.1992 38.25 33.9844V31.25C38.25 31.0352 38.0742 30.8594 37.8594 30.8594ZM8.5625 26.9531H11.2969C11.5117 26.9531 11.6875 26.7773 11.6875 26.5625V19.5312C11.6875 19.3164 11.5117 19.1406 11.2969 19.1406H8.5625C8.34766 19.1406 8.17188 19.3164 8.17188 19.5312V26.5625C8.17188 26.7773 8.34766 26.9531 8.5625 26.9531ZM15.9844 26.9531H18.7188C18.9336 26.9531 19.1094 26.7773 19.1094 26.5625V10.9375C19.1094 10.7227 18.9336 10.5469 18.7188 10.5469H15.9844C15.7695 10.5469 15.5938 10.7227 15.5938 10.9375V26.5625C15.5938 26.7773 15.7695 26.9531 15.9844 26.9531ZM23.4062 26.9531H26.1406C26.3555 26.9531 26.5312 26.7773 26.5312 26.5625V14.7461C26.5312 14.5312 26.3555 14.3555 26.1406 14.3555H23.4062C23.1914 14.3555 23.0156 14.5312 23.0156 14.7461V26.5625C23.0156 26.7773 23.1914 26.9531 23.4062 26.9531ZM30.8281 26.9531H33.5625C33.7773 26.9531 33.9531 26.7773 33.9531 26.5625V7.03125C33.9531 6.81641 33.7773 6.64062 33.5625 6.64062H30.8281C30.6133 6.64062 30.4375 6.81641 30.4375 7.03125V26.5625C30.4375 26.7773 30.6133 26.9531 30.8281 26.9531Z"
        fill="#1D70B8"
      />
    </svg>
  );
};

export default ChartPlaceholderIcon;
